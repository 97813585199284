//color
$primary: #BEA493;
$primary-higlight: #713318;
$footer-brown: #8F5D42;
$sky-blue: #D6E4F1;
$dark-gray: #383838;
$special-white: #F3F7FB;

//font family
@font-face {
  font-family: "SourceSansPro";
  src: url("../../assets/font/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype");
}