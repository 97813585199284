.slick-arrow {
  position: absolute;
  right: 0;
  top: -3.938rem;
  border: 0;
  background: none;

  &.slick-prev {
    right: 3.125rem
  }

  svg {
    height: 2.25rem;
    width: 2.25rem;
  }
}

.slick-dots {
  display: flex;
  list-style: none;
  padding-top: 1.25rem;
  padding-left: 0;
  justify-content: center;

  li {
    button {
      border: 1px solid $primary-higlight;
      text-indent: -50000px;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      margin: 0.225rem;
      background-color: $primary;
    }

    &.slick-active {
      button {
        background-color: $primary-higlight;
      }
    }
  }
}

.home-banner {
  .slick-dots {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);

    li {
      button {
        border: none;
        width: 0.9rem;
        height: 0.9rem;
      }

      &.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }
}

.tile-img-bg {
  .prefrences-slider-nav {
    color: black;
  }
}

.day-info {
  bottom: 0px;
  color: $white;

  .day-number {
    font-family: 'Migra';
    font-size: 2.5rem;
    padding-bottom: 20px;
  }
}

.tile-img-bg {
  .slick-dots {
    position: absolute;
    left: 50%;
    top: 85%;
    transform: translate(-50%, -50%);

    li {
      button {
        border: none;
        width: 0.9rem;
        height: 0.9rem;
      }

      &.slick-active {
        button {
          background-color: $white;
        }
      }
    }
  }

  .prefrences-slider-nav {
    .slick-list {
      display: none;
    }

    .slide-tem {
      color: $primary;
      font-size: 1.875rem;

      &.slick-current {

        color: $primary-higlight;

      }
    }

    .slick-dots {
      display: flex;
      flex-direction: column;
      color: $primary;
      font-size: 1.875rem;
      @include media-breakpoint-down(xl) {
        font-size: 1.25rem;
      }
      position: inherit;
      top: 20%;
      @include media-breakpoint-down(xl) {
        transform: translate(-50%, -13%);
      }
      a {
        text-decoration: none;
        cursor: pointer;
      }

      li {
        &.slick-active {
          a {
            color: $primary-higlight;
          }
        }
      }
    }
  }
}

.day-itinerary{
  .slick-arrow{
    top:50%;
    z-index: 1;
    &.slick-prev{
      left: -43px;
      right: auto;
      @include media-breakpoint-down(xl) {
        left: -35px;
      }
    }
    &.slick-next{
      right: -43px;
      @include media-breakpoint-down(xl) {
        right: -35px;
      }
    }
  }
}