body {
  font-family: 'SourceSansPro';

  img {
    width: 100%;
    height: auto;
  }

  .primary-link {
    text-decoration: none;
    vertical-align: middle;
    font-weight: bold;

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-left: 0.9375rem;
    }

    &:hover {
      text-decoration: underline;
      color: $primary-higlight;;
    }
  }

  .btn-primary {
    background-color: $footer-brown;
    border: none;
    border-radius: 15px;
    padding: 5px 25px;
    &:hover,
    &:active{
      background-color: $primary-higlight;
    }
  }

  .btn-secondary {
    background-color: $white;
    border: none;
    border-radius: 15px;
    padding: 5px 25px;
    &:hover,
    &:active{
      background-color: $white;
    }
  }

  .secondary-bg {
    background-color: $sky-blue;
  }

  .brown-bg {
    background-color: $footer-brown;

    ul {
      list-style: none;
      padding-left: initial;

      li {
        display: inline;
        color: $white;

        a {
          color: $white;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .set-gradient{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: linear-gradient(90deg, 
    rgba(113,51,24, 0.6) 2%, 
    rgba(143,93,66, 0.05) 70.30%, 
    rgba(143,93, 66, 0.005) 95.89%, 
    rgba(143,93, 66, 0) 100%);
    &.right-to-left{
      rotate: -180deg;
    }
  }

  .tile-img-bg {
    background-image: url('/assets/images/tileBackground.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    line-height: 1.5;
  }

  .brown-img-bg {
    background-image: url('/assets/images/brownImage.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    line-height: 1.5;

    p {
      font-size: 1.875rem;

      /*
      @include media-breakpoint-down(xl) {
        font-size: 1.25rem;
      }*/
    }
    svg{
      /*
      @include media-breakpoint-down(xl) {
        height: 70px;
        width: 80px;
      }*/
    }
  }

  .wild-life {
    position: relative;
    background-image: url('/assets/images/Rechteck 40.jpg');

    .banner-gradient {
      position: absolute;
    }
  }

  .set-image-background {
    background-image: url('/assets/images/imageBackground.svg');
    background-repeat: no-repeat;
    background-position: left 3.125rem top 3.125rem;
    background-size: cover;
    @include media-breakpoint-down(xl) {
      background-position: left 1rem top 1rem;
    }
  }

  .set-image-background-square {
    background-image: url('/assets/images/imageBacgroundSquare.svg');
    background-repeat: no-repeat;
    background-position: left 3.125rem top;
    background-size: cover;
    .pushup-display-image{
      margin-top: -1.875rem;
    }
  }

  .background-dots {
    background-image: url("/assets/images/Gruppe 136.png");
  }

  .reverse-item{
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }
  .sub-title {
    font-family: 'SourceSansPro';
    font-size: 3.75rem;
    color: $primary-higlight;

    @include media-breakpoint-down(xl) {
      font-size: 1.65rem;
    }

    &.dark-gray {
      color: $dark-gray;
    }

    &.special-white {
      color: $special-white;
    }
  }

  .text-block {
    p {
      font-size: 1.25rem;
    }
  }

  .w-50 {
    @include media-breakpoint-down(xl) {
      width: 100% !important;
    }
  }

  .element-center {
    position: relative;

    img {
      @include media-breakpoint-down(xl) {
        height: 320px;
      }
    }

    h2 {
      position: absolute;
      color: $white;
      font-family: 'SourceSansPro';
      font-size: 3.75rem;
      /*left: 0;*/
      /*right: 5%;*/
      top: 10%;
      bottom: 0;
      margin: auto;
      margin-top: 10%;
      /*width: 30%;*/
      margin-left: auto !important;
      margin-right: auto !important;
      max-width: 400px;
      display: block;

      @include media-breakpoint-down(xl) {
        font-size: 2rem;
        margin-top: 5%;
      }
      @include media-breakpoint-between(sx, md) {
        width:75%;
      }
    }


    .banner-gradient {
      position: absolute;
    }
  }

  .banner-item {
    position: relative;

    .banner-tag-line {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 500px;
    }
  }


  .back-image {
    margin-left: 1.875rem;
    margin-top: 1.875rem;
  }

  .display-image {
    padding-right: 2.8125rem;
    padding-bottom: 2.8125rem;
    @include media-breakpoint-down(xl) {
      padding-right: 1rem;
      padding-bottom: 1rem;
    }
  }

  .pushup-display-image {
    margin-top: -1.875rem;
    @include media-breakpoint-down(xl) {
      margin-top: auto;
    }
  }

  .list-item {
    list-style-type: none;
    padding-left: 0.9375rem;

    li {
      text-indent: -1.0625rem;

      &:before {
        content: "-";
        text-indent: -0.438rem;
        margin-right: 0.6rem;
      }
    }
  }
  .giraaf-foot-print{
    position: absolute;
    left: -10px;
    top: -10px;
    @include media-breakpoint-down(xl) {
      left: 0px;
      top: -10px;
    }
    svg{
      height: 88px;
      width: 74px;
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .main-banner-info h1 {
      font-size: 2.5rem; /* Increase font size for tablets */
    }

    .main-banner-info p {
      font-size: 1.25rem; /* Increase font size for tablets */
    }
  }



}

.contact {
  .slick-dots {
    display: none;
  }
}
