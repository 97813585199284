.main-banner {
  position: relative;
  
  .main-banner-info {
    left: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
    @include media-breakpoint-down(xl) {
      padding: 10px;
    }
  }

  h1 {
    color: $white;
    font-size: 7.5rem;
    font-family: 'SourceSansPro';
    @include media-breakpoint-down(xl) {
      font-size: 1.75rem;
    }

    @include media-breakpoint-down(xl) {
      font-size: 4.75rem;
    }

    @include media-breakpoint-down(lg) {
      font-size: 3.75rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 2.75rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.75rem;
    }
  }

  p {
    color: $white;
    font-size: 1.875rem;
    @include media-breakpoint-down(xl) {
      font-size: 0.875rem;
    }
  }

  img {
    @include media-breakpoint-down(xl) {
      height: auto;
    }
  }
}