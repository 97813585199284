.accordion {

  .accordion-item {
    border-bottom: solid 2px $primary;
    padding: 0.938rem 0 0.625rem 0;

    .accordion-body {
      padding-left: 3.125rem;
      font-size: 1.20rem;
      padding-bottom: 1.875rem;
      padding-top: 0.625rem;
      letter-spacing: 1.5px;
      line-height: 1.5;
    }

    .accordion-header {
      h3 {
        font-size: 1.875rem;
        font-weight: bold;
        padding-left: 0;
        display: flex;
        margin-block-end: 0;
        @include media-breakpoint-down(xl) {
          font-size: 1.5rem;
        }

        .facility-item {
          display: inline-block;
          min-width: 2.5rem;
          text-align: center;
          margin-right: 0.625rem;
        }
        .control-arrow{
          margin-left: auto;
          svg{
            width: 1.25rem;
            rotate: -180deg;
            transition-duration: 0.45s;
          }
        }
      }
      .accordion-button{
        &:after{
          display: none;
        }
        &.collapsed{
          .control-arrow{
            svg{
              rotate: 0deg;
              transition-duration: 0.45s;
            }
          }
        }
      }
      
      .location {
        svg {
          width: 2.25rem;
          height: 2.938rem;
        }
      }

      .health {
        svg {
          width: 2.5rem;
          height: 2.188rem;
        }
      }

      .room-key {
        svg {
          width: 1.5rem;
          height: 2.625rem;
        }
      }

      .luggage {
        svg {
          width: 1.75rem;
          height: 3.125rem;
        }
      }

      .rates {
        svg {
          width: 2.563rem;
          height: 2.563rem;
        }
      }
    }

    .accordion-button {
      &:not(.collapsed) {
        background-color: initial;
        color: initial;
      }
    }

    &:last-of-type {
      border: none;
    }
  }
}