.img-filter {
  position: relative;
  &:hover > img {
    //opacity: 0.95;
  }
  &:before {
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    content: "";
    background-color: #D9753D;
    opacity: 0.51;
    mix-blend-mode: multiply;

  }
}